import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import {
  AttachmentModel,
  RoamToastrService,
} from "@app/pages/task/+data-access";
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from "@app/pages/task/+ui/confirm-dialog/confirm-dialog.component";
import {
  SelectionsDialogComponent,
  SelectionsDialogData,
} from "@app/pages/task/+ui/selections-dialog/selections-dialog.component";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { RoamToggleSliderComponent } from "@app/shared/components/button/roam-toggle-slider/roam-toggle-slider.component";
import { FileUploaderComponent } from "@app/shared/components/file-uploader";
import { RoamDatepickerComponent } from "@app/shared/components/roam-datepicker/roam-datepicker.component";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { RoamTextAreaComponent } from "@app/shared/components/roam-text-area/roam-text-area.component";
import { RoamTimePickerComponent } from "@app/shared/components/roam-time-picker/roam-time-picker.component";
import { RoamTimeComponent } from "@app/shared/components/roam-time/roam-time.component";
import { IUser } from "@app/shared/interfaces";
import { AssociationService } from "@app/shared/services/association.service";
import { MeetingService } from "@app/shared/services/meeting.service";
import { addMinutes, differenceInMinutes, startOfDay, toDate } from "date-fns";
import { InlineSVGModule } from "ng-inline-svg-2";
import { filter, finalize, first, tap } from "rxjs";
import { MeetingModel, MeetingReqBody } from "../../+data-access";

export interface MeetingFormDialogData {
  detail?: MeetingModel;
}

interface DropdownItem {
  id: string | number;
  name: string;
  label?: string;
  value?: string;
  icon?: string;
}

const statusList: DropdownItem[] = [
  {
    id: 0,
    name: "Repeat",
  },
  {
    id: 1,
    name: "Does Not Repeat",
  },
];

const meetingTypeList: DropdownItem[] = [
  {
    id: 0,
    name: "Meeting Type I",
  },
  {
    id: 1,
    name: "Meeting Type II",
  },
];

@Component({
  standalone: true,
  selector: "app-meeting-form-dialog",
  styleUrl: "meeting-form-dialog.component.scss",
  templateUrl: "meeting-form-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatSlideToggleModule,
    FileUploaderComponent,
    RoamSelectComponent,
    RoamInputComponent,
    RoamDatepickerComponent,
    RoamToggleSliderComponent,
    RoamTextAreaComponent,
    RoamButtonComponent,
    RoamTimeComponent,
    RoamTimePickerComponent,
    InlineSVGModule,
  ],
})
export class MeetingFormDialogComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  #fb = inject(NonNullableFormBuilder);
  #api = inject(MeetingService);
  #associationApi = inject(AssociationService);
  protected dialog = inject(MatDialog);
  protected toastr = inject(RoamToastrService);
  protected userConfig = inject(UserConfigStore);
  protected dialogRef = inject(MatDialogRef);
  protected data = inject<MeetingFormDialogData>(MAT_DIALOG_DATA);
  protected isSubmitting = signal(false);
  protected attachments = signal<AttachmentModel[]>([]);
  protected propertyId = signal("");

  protected readonly now = new Date();

  get detail() {
    return this.data?.detail;
  }

  get title() {
    return this.detail ? "Edit Meeting" : "Add Meeting";
  }

  protected uploadConfig = computed(() => {
    return {
      propertyId: this.propertyId(),
      modelId: this.detail?.id || crypto.randomUUID(),
      model: "meeting",
    };
  });

  protected opts = {
    statuses: signal<DropdownItem[]>(statusList),
    meetingTypes: signal<DropdownItem[]>(meetingTypeList),
    guests: signal<IUser[]>([]),
  };

  protected loaders = {
    guestsLoading: signal(false),
    unitsLoading: signal(false),
  };

  get displayedGuests() {
    return this.opts
      .guests()
      .filter(u => this.controls.userIds.value?.includes(u.id));
  }

  readonly form = this.#fb.group({
    date: this.#fb.control(startOfDay(this.now), Validators.required),
    startMinutes: this.#fb.control(0, Validators.required),
    endMinutes: this.#fb.control(0, Validators.required),
    isAllDay: this.#fb.control(false),
    status: this.#fb.control(0, Validators.required),
    subject: this.#fb.control("", Validators.required),
    type: this.#fb.control(0, Validators.required),
    userIds: this.#fb.control<string[] | null>(null, Validators.required),
    notes: this.#fb.control("", Validators.required),
  });

  get controls() {
    return this.form.controls;
  }

  fieldError(name: keyof typeof this.form.controls) {
    return this.form.controls[name].invalid && this.form.controls[name].touched;
  }

  openAddGuestsDialog(): void {
    this.dialog
      .open(SelectionsDialogComponent, {
        width: "auto",
        backdropClass: "roam-dialog-backdrop",
        panelClass: "roam-dialog-panel",
        data: <SelectionsDialogData>{
          title: "Add Meeting Guests",
          confirmLabel: "Add Guests",
          cancelLabel: "Cancel",
          selected: this.controls.userIds.value,
          options: this.opts.guests().map(u => {
            return {
              key: u.id,
              label: u.name,
            };
          }),
          multi: true,
        },
      })
      .afterClosed()
      .subscribe(keys => {
        if (keys) {
          this.controls.userIds.patchValue(keys || []);
          this.cdr.detectChanges();
        }
      });
  }

  openDeleteGuestConfirmDialog(u: IUser) {
    this.dialog
      .open(ConfirmDialogComponent, {
        backdropClass: "roam-dialog-backdrop",
        panelClass: "roam-dialog-panel",
        width: "700px",
        data: <ConfirmDialogData>{
          message: `Do you want to remove ${u.name} from current guest list?`,
          title: "Remove Guest",
          confirmLabel: "Confirm",
          cancelLabel: "Abort",
        },
      })
      .afterClosed()
      .pipe(first(), filter(Boolean))
      .subscribe(() => {
        const newUserIds =
          this.controls.userIds?.value?.filter(ids => !ids.includes(u.id)) ||
          [];
        this.controls.userIds.patchValue(newUserIds);
        this.cdr.detectChanges();
      });
  }

  submitCreate(body: MeetingReqBody.CreateOne): void {
    this.#api
      .createMeeting(body)
      .pipe(
        finalize(() => this.isSubmitting.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        error: () => {
          this.toastr.danger("Failed to save meeting!");
        },
        next: resp => {
          this.toastr.success("New meeting saved successfully!");
          console.log(resp);
          this.close(resp);
        },
      });
  }

  submitPatch(id: string, body: MeetingReqBody.PatchOne): void {
    this.#api
      .patchMeeting(id, body)
      .pipe(
        finalize(() => this.isSubmitting.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        error: () => {
          this.toastr.danger("Failed to save meeting!");
        },
        next: resp => {
          this.toastr.success("Meeting updated successfully!");
          console.log(resp);
          this.close(resp);
        },
      });
  }

  formatReqPayload = () => {
    const { startMinutes, endMinutes, ...props } = this.form.getRawValue();
    const date = startOfDay(props.date);
    return {
      ...props,
      type: (props.type as any) || "", // TODO: make sure the correct value type
      startDateTime: addMinutes(date, Math.abs(startMinutes)),
      endDateTime: addMinutes(date, Math.abs(endMinutes)),
      attendees: props.userIds?.map(x => ({ userId: x })),
      isRecurring: props.isAllDay,
    } satisfies MeetingReqBody.CreateOne | MeetingReqBody.PatchOne;
  };

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const body = this.formatReqPayload();
    if (this.detail?.id) {
      this.submitPatch(this.detail.id, body);
    } else {
      this.submitCreate(body);
    }
  }

  close(newData?: any) {
    this.dialogRef.close(newData);
  }

  protected initOptions = () => {
    const propertyId = this.propertyId();
    this.loaders.guestsLoading.set(true);
    this.#associationApi
      .getAssociationBoardMembers<IUser[]>(propertyId)
      .pipe(
        finalize(() => this.loaders.guestsLoading.set(false)),
        tap(resp => {
          const items = resp || [];
          this.opts.guests.set(items);
        })
      )
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  };

  protected setupEditForm = () => {
    const v = this.detail;
    if (!v) return;
    // TODO: verify meeting files upload?
    this.attachments.set(v.files || []);
    const date = toDate(v.startDateTime ?? this.now);
    const beginning = startOfDay(date);
    const startMinutes = Math.abs(
      differenceInMinutes(beginning, toDate(v.startDateTime || beginning))
    );
    const endMinutes = Math.abs(
      differenceInMinutes(beginning, toDate(v.endDateTime || beginning))
    );
    this.form.patchValue({
      date: beginning,
      startMinutes,
      endMinutes,
      status: v.status || 0,
      isAllDay: !!v.isRecurring,
      subject: v.subject || "",
      type: v.type ?? 0, // TODO:
      notes: v.notes || "",
      userIds: v.attendees?.map(x => x.userId),
    });
  };

  ngOnInit(): void {
    this.initOptions();
    this.setupEditForm();

    // TODO: get meeting types if api already available
    // this.#api.getMeetingTypes().subscribe(resp => {
    //   console.warn("Meeting Types ", resp);
    // })
  }

  constructor(private cdr: ChangeDetectorRef) {
    const associationId = toSignal(this.userConfig.selectedAssociationId$, {
      initialValue: "",
    });
    const defaultSelected = this.detail?.propertyId || associationId() || "";
    this.propertyId.set(defaultSelected);
  }
}
