import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IResponseDTO } from "../interfaces/response.interface";

@Injectable({
  providedIn: "root",
})
export class MeetingService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getMeetings(
    property: string = "",
    limit: number = 10,
    page: number = 1,
    filter: any = {},
  ): Observable<IResponseDTO<any[]>> {
    const body = {
      filter,
      property,
      limit,
      page,
    };

    return this.http.post<IResponseDTO<any[]>>(
      `${environment.apiUrl}/meetings/search`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  public getMotion = (id: string): Observable<any> => {
    return this.http.get<any>(`${this.apiUrl}/meetings/${id}`, {
      withCredentials: true,
    });
  };

  // TODO: verify if this api is available!
  getMeetingTypes = <T = any>() => {
    return this.http.get<T>(`${this.apiUrl}/meeting-types`);
  };

  public createMeeting<T = any>(body: any): Observable<any> {
    return this.http.post<T>(`${this.apiUrl}/meetings`, body);
  }

  patchMeeting<T = any>(id: string, body: any) {
    return this.http.patch<T>(`${this.apiUrl}/meetings/${id}`, body);
  }

  deleteMeeting(id: string) {
    return this.http.delete<null>(`${this.apiUrl}/meetings/${id}`);
  }
}
