@if (isSubmitting()) {
  <div class="spinner-wrapper">
    <mat-spinner />
  </div>
}

<div class="dialog-header">
  <h2 mat-dialog-title>
    {{ title }}
  </h2>
</div>

<mat-dialog-content>
  <section class="form">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="set-grid set-grid-3 stretch mt-25">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="fieldError('date')">
          <app-roam-datepicker name="Meeting Date" formControlName="date" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="fieldError('startMinutes')">
          <roam-time-picker
            label="Start Time"
            formControlName="startMinutes"
            placeholder="Select Start Time" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="fieldError('endMinutes')">
          <roam-time-picker
            label="End Time"
            formControlName="endMinutes"
            placeholder="Select End Time" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>

      <div class="mt-25">
        <app-roam-toggle-slider
          name="allDay"
          label="All Day"
          formControlName="isAllDay" />
      </div>

      <div class="set-grid set-grid-2 stretch mt-25">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="fieldError('status')">
          <app-roam-select
            placeholder="Select Status"
            formControlName="status"
            [items]="opts.statuses()"
            [searchable]="false" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div></div>
      </div>

      <div class="set-grid set-grid-2 stretch mt-25">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="fieldError('subject')">
          <app-roam-input
            class="w-50"
            name="Meeting Subject"
            formControlName="subject" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="fieldError('type')">
          <app-roam-select
            placeholder="Select Meeting Type"
            formControlName="type"
            [items]="opts.meetingTypes()"
            [searchable]="false" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>

      <div class="mt-25">
        <p class="form-label">
          <span class="text">Guests</span>
        </p>
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="fieldError('userIds')">
          <div class="chip-item-list">
            @for (opt of displayedGuests; track opt.id) {
              <div mat-flat-button class="chip-item">
                <div
                  class="chip-icon"
                  [inlineSVG]="
                    opt.avatarUrl || 'assets/svg/ic-user-round.svg'
                  "></div>
                <div class="chip-label">{{ opt.name }}</div>
                <mat-icon
                  fontSet="material-icons-outlined"
                  matTooltipPosition="above"
                  matTooltip="Remove"
                  class="chip-close-icon"
                  (click)="openDeleteGuestConfirmDialog(opt)">
                  close
                </mat-icon>
              </div>
            }
            <button
              (click)="openAddGuestsDialog()"
              mat-icon-button
              class="add-chip-button"
              type="button">
              <mat-icon fontSet="material-icons-outlined">add</mat-icon>
            </button>
          </div>
          <p class="mt-5 roam-form-error-message">Need at least one guest</p>
        </div>
      </div>

      <div class="mt-30">
        <div
          class="roam-form-field"
          [class.roam-form-field-error]="fieldError('notes')">
          <app-roam-text-area
            name="Additional Information"
            formControlName="notes" />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>

      <div class="mt-25">
        <p class="form-label">
          <span class="text">Attachments</span>
        </p>
        <div class="form-field attachment-form-field">
          <app-file-uploader [(data)]="attachments" [config]="uploadConfig()" />
        </div>
      </div>
    </form>
  </section>
</mat-dialog-content>

<mat-dialog-actions>
  <a class="cancel-button" (click)="close()">
    <span class="label">Cancel</span>
  </a>
  <app-roam-button label="Save" (onClick)="save()" />
</mat-dialog-actions>
